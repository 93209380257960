import { v3WithImageBg } from '@consumer/logic/v3'

export function giftlyBackgroundUrl (designName: string) {
  if (designName !== v3WithImageBg) {
    return `https://assets2.giftly.com/giftly-assets/giftly-designs/backgrounds/${designName}.png`
  }
}

export function extractUrlPath (url: string) {
  const urlObj = new URL(url)
  return [urlObj.pathname, urlObj.search].filter(Boolean).join('')
}
