// JsFromRoutes CacheKey c3a915e88ef46b00dd14fcfddd6c2ca4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/gift-card-checkout'),
  shoppingDirectCheckout: /* #__PURE__ */ definePathHelper('get', '/consumer/checkout/shopping_direct_checkout'),
  configuration: /* #__PURE__ */ definePathHelper('get', '/consumer/checkout/configuration'),
  validateEmail: /* #__PURE__ */ definePathHelper('post', '/consumer/checkout/validate_email'),
  verifyEmail: /* #__PURE__ */ definePathHelper('post', '/consumer/checkout/verify_email'),
  saveProgress: /* #__PURE__ */ definePathHelper('patch', '/consumer/checkout/save_progress'),
  subscribeToNewsletter: /* #__PURE__ */ definePathHelper('put', '/consumer/checkout/subscribe_to_newsletter'),
}
